<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="title_option">
          <h5 class="title">완제품 불량률(Q)</h5>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div class="select_option input_text">
          <span>실적년도</span>
          <datePicker
            type="year"
            minimum-view="year"
            :value="QTargetYear"
            @selected="changeTargetYear($event)"
            :format="DatePickerFormat"
          />
        </div>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide class=" swiper-slide">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>No</th>
                  <th>분야</th>
                  <th>핵심지표(KPI)</th>
                  <th>단위</th>
                  <th>측정대상</th>
                  <th>기존</th>
                  <th>목표</th>
                  <th>실적</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredKPI" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.kpi_kind }}</td>
                  <td>{{ item.kpi_name }}</td>
                  <td>{{ item.unit_cd }}</td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>
                    {{ item.crt_val }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ item.tgt_val }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ item.year_total_performance
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td class="text_left">
                    {{ '⇒ [실적 :'
                    }}<b>{{
                      ` ${item.year_total_performance} ${item.unit_cd}`
                    }}</b
                    >{{ '] = ([불량품 : '
                    }}<b>{{ $makeComma(item.fail_quantity) }}</b
                    >{{ '] / [생산량 : '
                    }}<b>{{ $makeComma(item.total_quantity) }}</b
                    >{{ ']) X 100' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-slide">
          <line-chart
            :data="chartData.data"
            :options="chartData.options"
            :key="key"
          ></line-chart>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LineChart from '@/layouts/components/charts/LineChart';
import datePicker from 'vuejs-datepicker';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ModalMixin, SpinnerMixin, FavoriteMixin, FetchMixin, SWIPER_MIXIN],
  components: {
    LineChart,
    datePicker,
  },
  data() {
    return {
      fullScreen: false,
      DatePickerFormat: 'yyyy',
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      kpi: 'getKpiTarget',
      products: 'getVisibleProduct',
      QTargetYear: 'getQTargerYearFromKpiTarget',
      units: 'getUnitCodes',
      lotTypes: 'getLotType',
      productionYear: 'getLotYearListFromProduction',
    }),
    filteredKPI() {
      if (this.kpi.length > 0) {
        const yearDataList = this.lodash.clonedeep(this.makeKpiData[0]);
        const QList = this.lodash
          .clonedeep(this.kpi)
          .filter(x => x.kpi_kind == 'Q')
          .map(x => {
            let findProduct = this.lodash
              .clonedeep(yearDataList)
              .filter(y => x.product_id == y.product_id);
            if (findProduct.length > 0) {
              findProduct.forEach(el => {
                if (x.product_id == el.product_id) {
                  x.total_quantity = el.total_quantity;
                  x.fail_quantity = el.fail_quantity;
                  x.year_total_performance = el.year_total_performance;
                }
              });
            } else {
              x.total_quantity = 0;
              x.fail_quantity = 0;
              x.year_total_performance = 0;
            }
            return x;
          });

        return QList;
      } else return [];
    },
    makeKpiData() {
      if (this.productionYear != null) {
        let productionList = this.lodash.clonedeep(this.productionYear);

        for (let lot of productionList) {
          lot.production_month = lot.create_time.substring(5, 7);
          const total_quantity = this.lodash
            .clonedeep(lot)
            .lot_process.reduce(
              (a, b) => (a.total_quantity > b.total_quantity ? a : b),
              0,
            ).total_quantity;
          const fail_quantity = this.lodash
            .clonedeep(lot)
            .lot_process.reduce(
              (a, b) => (a.fail_quantity > b.fail_quantity ? a : b),
              0,
            ).fail_quantity;
          lot.total_quantity = total_quantity;
          lot.fail_quantity = fail_quantity;
        }
        const kpiProductList = this.lodash
          .clonedeep(this.kpi)
          .filter(x => x.kpi_kind == 'Q')
          .map(x => x.product_id);

        // 달별 총합
        console.log('kpiProductList : ', kpiProductList);
        const monthGroup = this.lodash
          .clonedeep(productionList)
          .filter(x => kpiProductList.includes(x.product_id))
          .reduce(function(rv, x) {
            (rv[x['production_month']] = rv[x['production_month']] || []).push(
              x,
            );
            return rv;
          }, {});

        let monthTotalList = [];
        for (let k in monthGroup) {
          let index = Number(k) - 1;
          for (let i = 0; i < 12; i++) {
            if (index == i) {
              monthTotalList.push(
                this.$decimalMul(
                  this.$decimalDiv(
                    monthGroup[k]
                      .map(x => x.fail_quantity)
                      .reduce((a, b) => this.$decimalAdd(a, b), 0),
                    monthGroup[k]
                      .map(x => x.total_quantity)
                      .reduce((a, b) => this.$decimalAdd(a, b), 0),
                  ),
                  100,
                ).toFixed(2),
              );
            } else {
              monthTotalList.push(0);
            }
          }
        }
        console.log('monthTotalList ', monthTotalList);

        // 제품별 총합
        const productGroup = this.lodash
          .clonedeep(productionList)
          .filter(x => kpiProductList.includes(x.product_id))
          .reduce(function(rv, x) {
            (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
            return rv;
          }, {});

        let producTotalList = [];
        for (let item in productGroup) {
          console.log('item', item);
          const total_quantity = productGroup[item]
            .map(x => x.total_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const fail_quantity = productGroup[item]
            .map(x => x.fail_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          producTotalList.push({
            product_id: item,
            fail_quantity: fail_quantity,
            total_quantity: total_quantity,
            year_total_performance: this.$decimalMul(
              this.$decimalDiv(fail_quantity, total_quantity),
              100,
            ).toFixed(2),
          });
        }

        return [producTotalList, monthTotalList];
      } else return [[], []];
    },
    chartData() {
      const data = {
        labels: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월',
        ],
        datasets: [
          {
            label: '불량률',
            data: this.makeKpiData[1],
            borderColor: '#272f68',
            backgroundColor: '#272f68',
            fill: false,
            borderWidth: 2,
            bezierCurve: false,
          },
        ],
      };
      let options = {
        legend: {
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.1,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
    },
  },
  methods: {
    async changeTargetYear(e) {
      this.$store.commit('setQTargerYearToKpiTarget', String(e.getFullYear()));

      this.showSpinner();

      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.QTargetYear,
        'KPI목표',
      );
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_LOT_PRODUCTION_YEAR',
        this.QTargetYear,
      );

      this.hideSpinner();
    },
  },
  async created() {
    this.showSpinner();

    if (this.QTargetYear == null) {
      let year = String(new Date().getFullYear());
      this.$store.commit('setQTargerYearToKpiTarget', year);
    }
    if (this.products.length == 0) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_UNIT', '단위');
    }
    if (this.lotTypes.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_LOT_TYPE', '작업상태');
    }

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_LOT_PRODUCTION_YEAR',
      this.QTargetYear,
      '작업지시서',
    );
    if (this.kpi.length < 1) {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.QTargetYear,
        'KPI목표',
      );
    }
    this.key++;

    this.hideSpinner();
  },
};
</script>

<style></style>
