<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="title_option">
          <h5 class="title">수주출하 리드타임(D)</h5>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div class="select_option">
          <span>실적년도</span>
          <div class="input_text">
            <datePicker
              type="year"
              minimum-view="year"
              :value="DTargetYear"
              :format="DatePickerFormat"
              @selected="changeTargetYear($event)"
            />
          </div>
        </div>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide class=" swiper-slide">
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th>No</th>
                  <th>분야</th>
                  <th>핵심지표(KPI)</th>
                  <th>단위</th>
                  <th>측정대상</th>
                  <th>기존</th>
                  <th>목표</th>
                  <th>실적</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredKPI" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.kpi_kind }}</td>
                  <td>{{ item.kpi_name }}</td>
                  <td>{{ item.unit_cd }}</td>
                  <td>{{ findInfoFromId(products, item.product_id).name }}</td>
                  <td>
                    {{ $makeComma(item.crt_val)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ $makeComma(item.tgt_val)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td>
                    {{ $makeComma(item.count.sales_term)
                    }}<b>{{ ` ${item.unit_cd}` }}</b>
                  </td>
                  <td class="text_left">
                    {{ '⇒ [실적 :'
                    }}<b>{{
                      ` ${$makeComma(item.count.sales_term)} ${item.unit_cd}`
                    }}</b
                    >{{ '] = [수주일~출고일 기간합 평균 : '
                    }}<b>{{ $makeComma(item.count.daily_average) }}</b
                    >{{ '] X 24' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide chart-slide">
          <line-chart
            :data="chartData.data"
            :options="chartData.options"
            :key="key"
          ></line-chart>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import datePicker from 'vuejs-datepicker';
import LineChart from '@/layouts/components/charts/LineChart';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FetchMixin from '@/mixins/fetch';

export default {
  mixins: [FetchMixin, SWIPER_MIXIN],
  components: {
    LineChart,
    datePicker,
  },
  data() {
    return {
      fullScreen: false,
      DatePickerFormat: 'yyyy',
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      kpis: 'getKpiTarget',
      products: 'getProduct',
      DTargetYear: 'getDTargerYearFromKpiTarget',
      orderShipment: 'getKpiOrderShipmentLeadTime',
    }),
    filteredKPI() {
      if (this.kpis.length < 1) {
        return [];
      } else {
        let leadTime = this.lodash
          .clonedeep(this.kpis)
          .filter(x => x.kpi_kind.includes('D'))
          .map(x => ({
            ...x,
            count:
              this.countByProductId.find(y => y.product_id == x.product_id) !=
              undefined
                ? this.countByProductId.find(y => y.product_id == x.product_id)
                : {
                    sales_term: 0,
                    daily_average: 0,
                  },
          }));
        return leadTime;
      }
    },
    countByProductId() {
      if (this.orderShipment != undefined && this.orderShipment.length > 0) {
        const new_sales = this.lodash
          .clonedeep(this.orderShipment)
          .filter(x =>
            x.sales_product_list.map(x =>
              x.product_in_out_list.map(y => y.product_in_out_type == 3),
            ),
          );
        let filter_input_date = new_sales.map(x => {
          x.product_id = x.sales_product_list[0].product_id;
          x.sales_term = this.$decimalDiv(
            Number(
              Math.abs(
                new Date(x.sales.input_date) -
                  new Date(x.create_time.substr(0, 10)),
              ),
            ),
            24 * 3600 * 1000,
          );
          return x;
        });

        const obj = filter_input_date.reduce(function(rv, x) {
          (rv[x['product_id']] = rv[x['product_id']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          const dailyAverage = this.$decimalDiv(
            obj[k].map(x => x.sales_term).reduce((a, b) => a + b, 0),
            obj[k].map(x => x.sales_term).reduce((a, b, index) => index + 1),
          ).toFixed(2);
          arr.push({
            product_id: Number(k),
            daily_average: dailyAverage,
            sales_term: Math.round(this.$decimalMul(dailyAverage, 24)),
          });
        }

        return arr;
      } else {
        return [];
      }
    },
    countByMonth() {
      if (this.orderShipment != undefined && this.orderShipment.length > 0) {
        const new_sales = this.lodash
          .clonedeep(this.orderShipment)
          .filter(x =>
            x.sales_product_list.map(x =>
              x.product_in_out_list.map(y => y.product_in_out_type == 3),
            ),
          );
        let filter_input_date = new_sales.map(x => ({
          create_month: x.create_time.substr(0, 7),
          sales_term: Number(
            this.$decimalDiv(
              Math.abs(
                new Date(x.sales.input_date) -
                  new Date(x.create_time.substr(0, 10)),
              ),
              24 * 3600 * 1000,
            ),
          ),
        }));

        const obj = filter_input_date.reduce(function(rv, x) {
          (rv[x['create_month']] = rv[x['create_month']] || []).push(x);
          return rv;
        }, {});
        let arr = [];
        for (let k in obj) {
          arr.push({
            month: k,
            sales_term: Math.round(
              this.$decimalMul(
                this.$decimalDiv(
                  obj[k].map(x => x.sales_term).reduce((a, b) => a + b, 0),
                  obj[k]
                    .map(x => x.sales_term)
                    .reduce((a, b, index) => index + 1),
                ),
                24,
              ),
            ),
          });
        }
        return arr;
      } else {
        return [];
      }
    },
    chartData() {
      let countByMonth = this.countByMonth;
      // let filteredKPI = this.filteredKPI;
      const data = {
        labels: [
          '1월',
          '2월',
          '3월',
          '4월',
          '5월',
          '6월',
          '7월',
          '8월',
          '9월',
          '10월',
          '11월',
          '12월',
        ],
        datasets: [
          {
            label: '월별 실적',
            data:
              // filteredKPI.count != undefined
              // ?
              countByMonth.map(x => x.sales_term),
            // : '',
            borderColor: '#272f68',
            backgroundColor: '#272f68',
            fill: false,
            borderWidth: 2,
            bezierCurve: false,
          },
        ],
      };
      let options = {
        legend: {
          display: true,
          labels: {
            boxWidth: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0.1,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 78,
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: false,
        },
      };
      return { data: data, options: options };
    },
  },
  methods: {
    async changeTargetYear(e) {
      this.$store.commit('setDTargerYearToKpiTarget', String(e.getFullYear()));

      this.showSpinner();

      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.DTargetYear,
      );
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_LOT_PRODUCTION_YEAR',
        this.DTargetYear,
      );

      this.hideSpinner();
    },
  },
  async created() {
    this.showSpinner();

    const year = String(new Date().getFullYear());

    if (this.orderShipment.length < 1) {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_ORDER_SHIPMENT_LEAD_TIME',
        year,
        '수주출하',
      );
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.DTargetYear == null) {
      this.$store.commit('setDTargerYearToKpiTarget', year);
    }

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_LOT_PRODUCTION_YEAR',
      this.DTargetYear,
    );

    if (this.kpis.length < 1) {
      await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
        'FETCH_KPI_TARGET',
        this.DTargetYear,
      );
    }
    this.key++;
    this.hideSpinner();
  },
};
</script>

<style></style>
