<template>
  <div id="contents" class="kpi_monitor" :class="{ kpi_target: tabIndex == 3 }">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToKPIDefectiveRatePage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click.prevent="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="tabIndex == 3"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <ProductDefectRateForm v-if="tabIndex == 0" />
      <OrderShipmentLeadtimeForm v-if="tabIndex == 1" />
      <ProductionPerHourForm v-if="tabIndex == 2" />
      <KPITargetStatusForm v-if="tabIndex == 3" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import ProductDefectRateForm from '@/views/forms/Custom/Monitor/18/ProductDefectRateForm';
import OrderShipmentLeadtimeForm from '@/views/forms/Custom/Monitor/18/OrderShipmentLeadtimeForm';
import ProductionPerHourForm from '@/views/forms/Custom/Monitor/18/ProductionPerHourForm';
import KPITargetStatusForm from '@/views/forms/Custom/Monitor/18/KPITargetStatusForm';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    ProductDefectRateForm,
    OrderShipmentLeadtimeForm,
    ProductionPerHourForm,
    KPITargetStatusForm,
  },
  data() {
    return {
      swiper: null,
      key: 0,
      timer: 0,
      asideIndex: [
        { title: '완제품 불량률', name: 'finished_product_defect_rate' },
        { title: '수주출하 리드타임', name: 'order_shipment_leadtime' },
        { title: '시간당 생산량', name: 'production_per_hour' },
        { title: 'KPI 목표설정', name: 'kpi_target_status' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromKPIDefectiveRatePage',
      tabIndex: 'getOpenTabIndexFromKPIDefectiveRatePage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToKPIDefectiveRatePage',
      SetOpenTabIndex: 'setOpenTabIndexToKPIDefectiveRatePage',
    }),
    changeTimer(e) {
      this.timer = e;
    },
    async FETCH_PRODUCTION_LIST() {
      await this.$store
        .dispatch('FETCH_PRODUCTION_LIST')
        .then(() => {
          this.key += 1;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 진행현황을 불러오는 중 오류 발생',
          );
        });
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitKPIPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'bomnae_monitoring';
</style>
